import { __assign, __awaiter, __generator } from "tslib";
import * as React from 'react';
import { useAtom } from 'jotai';
import { useHistory } from 'react-router';
import { generateUUID } from '../../utils';
import useFetch from '../../utils/useFetch';
import { handleMfa, mfaInitialValue, profileAtom, } from '../../stores';
import { Loading } from '../loading';
import { Button, Grid, H6, Paragraph, Spacer } from '../elements';
import { Modal, ModalMessage } from '../modal';
import TopNav from './TopNav';
export default function SendCode(_a) {
    var _this = this;
    var onSuccess = _a.onSuccess, logo = _a.logo;
    var history = useHistory();
    var _b = useFetch(), state = _b[0], fetch = _b[1];
    var profile = useAtom(profileAtom)[0];
    var _c = useAtom(handleMfa), mfa = _c[0], setMfa = _c[1];
    var _d = React.useState(false), isLoading = _d[0], setIsLoading = _d[1];
    var _e = React.useState(''), contextID = _e[0], setContextID = _e[1];
    var _f = React.useState(''), type = _f[0], setType = _f[1];
    var _g = React.useState({
        show: false,
        title: '',
        message: '',
        success: false,
    }), modalState = _g[0], setModalState = _g[1];
    React.useEffect(function () {
        if (profile && !profile.accountCode) {
            setIsLoading(true);
        }
        if (profile && profile.accountCode) {
            setIsLoading(false);
        }
    }, [profile]);
    React.useEffect(function () {
        if (state.success) {
            if (state.data && state.data.success) {
                onSuccess(type, contextID);
                fetch.reset();
            }
            if (state.data && !state.data.success) {
                setModalState({
                    show: true,
                    success: false,
                    title: 'Send Code Error',
                    message: (React.createElement(ModalMessage, { centerMessage: true, message: state.data.message
                            ? state.data.message
                            : 'There was an error sending the code. Please try again.', hideIcon: true })),
                });
            }
            fetch.reset();
        }
        if (state.error) {
            setModalState({
                show: true,
                success: false,
                title: 'Send Code Error',
                message: (React.createElement(ModalMessage, { centerMessage: true, message: state.data.message
                        ? state.data.message
                        : 'There was an error sending the code. Please try again.', hideIcon: true })),
            });
            fetch.reset();
        }
    }, [state]);
    var requestMFA = function (mType) {
        setType(mType);
        var id = generateUUID();
        setContextID(id);
        var url = mType === 'sms'
            ? '/api/v1/mobile/initiate-port-in-verification'
            : '/api/v1/mobile/initiate-email-verification';
        var payload = __assign({ contextId: id, accountCode: profile.accountCode }, (mType === 'sms'
            ? { portInMsn: profile.contacts[0].phoneMobile }
            : { emailAddress: profile.email }));
        fetch.go(url, 'POST', JSON.stringify(payload), 'application/json');
    };
    var onClickHome = function () {
        setMfa(mfaInitialValue);
        history.push('/home');
    };
    var onClickBack = function () { return __awaiter(_this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            setMfa(__assign({}, mfaInitialValue));
            if (mfa.cbIsPath) {
                history.push('/home');
            }
            return [2];
        });
    }); };
    return (React.createElement(React.Fragment, null,
        (isLoading || state.loading) && React.createElement(Loading, { iconWidth: "90px" }),
        React.createElement(TopNav, { logo: logo, onBack: onClickBack, onClickHome: onClickHome }),
        React.createElement(H6, null, "Two Factor Authentication Required"),
        React.createElement(Paragraph, null, "Before you can use this feature, we just need to make sure it's you by using 2 factor authentication."),
        React.createElement(Paragraph, null, "Simply click on one of the options below and we will send you a unique 6 digit code. Once you get the email or SMS, you'll be prompted on the next screen to enter in the 6 digit code and confirm it's you. That's it."),
        React.createElement(Spacer, { height: "12px" }),
        React.createElement(Grid, { placeItems: "center", gap: "10px" },
            React.createElement(Button, { variant: "danger", style: { width: '150px' }, onClick: function () { return requestMFA('email'); } }, "Send Email"),
            React.createElement(Button, { variant: "danger", style: { width: '150px' }, onClick: function () { return requestMFA('sms'); } }, "Send SMS")),
        React.createElement(Modal, { hideCloseIcon: true, open: modalState.show, title: modalState.title, hideCancel: true, onOk: function () { return setModalState({ show: false, success: false, message: '' }); } }, modalState.message)));
}
